import { CutConnections } from './CutConnections'
import { UnrealizedPlan } from './UnrealizedPlan'

export class Problems {
  private problems: CutConnections[] | UnrealizedPlan[] = []

  add (problem: CutConnections | UnrealizedPlan): void {
    this.problems.push(problem)
  }

  find (id: string): CutConnections[] | UnrealizedPlan[] {
    return this.problems.filter((problem) => { return problem.ids.includes(id) })
  }
}
