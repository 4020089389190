import { ExclusionFactor } from './ExclusionFactor'

export class CommunityExclusionFactor implements ExclusionFactor {
    static readonly NoOptions = 'No options'
    static readonly VeryLimitedOptions = 'Very limited options'
    static readonly LimitedOptions = 'Limited options'
    static readonly RegularOptions = 'Regular options'
    static readonly GoodOptions = 'Good options'
    static readonly VeryGoodOptions = 'Very good options'
}
