interface GradeParameters {
    description: string;
    color: string;
}

export class Grade {
    public color: string;
    public description: string;

    constructor ({ description, color }: GradeParameters) {
      this.description = description
      this.color = color
    }
}
