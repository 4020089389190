import { ExclusionStrategy } from './ExclusionStrategy'
import { Community } from './community'
import { ExclusionFactor } from './ExclusionFactor'
import { TrainsExclusion } from './TrainsExclusion'

export class TrainsStrategy implements ExclusionStrategy {
  exclusion (community: Community): ExclusionFactor {
    if (community.trains.tracks === false) {
      return TrainsExclusion.noTracks
    } else if (community.trains.tracks && !(community.trains.passengerTrains)) {
      return TrainsExclusion.tracksOnly
    } else {
      return TrainsExclusion.trainsAvailable
    }
  }
}
