export class LayerStyle {
    readonly color: string
    readonly weight: number
    readonly dash?: number[]
    readonly lineCap?: string

    constructor (color: string, weight: number, dash?: number[], lineCap?: string) {
      this.color = color
      this.weight = weight
      this.dash = dash
      this.lineCap = lineCap
    }
}
