const maps = require('./src/map.ts')
const shapes = require('./src/shapes')
const { Communities } = require('./src/communities')
const {
  loadGeoData, createVectorGridFactory, createSimpleVectorGrid,
  loadGeoJSONLayer, createLegend, createProblemVectorGridFactory
} = require('./src/map')
const { Community } = require('./src/community')
const { LayerStyle } = require('./src/LayerStyle')
const { CoursesPerCapitaStrategy } = require('./src/CoursesPerCapitaStrategy')
const { ExclusionCalculator } = require('./src/ExclusionCalculator')
const { CoursesPerCapitaRanges } = require('./src/CoursesPerCapitaRanges')
const { LateAfternoonCoursesPerCapitaStrategy } = require('./src/LateAfternoonCoursesPerCapitaStrategy')
const { LateAfternoonCoursesPerCapitaRanges } = require('./src/LateAfternoonCoursesPerCapitaRanges')
const { SchoolDaysCoursesPerCapitaStrategy } = require('./src/SchoolDaysCoursesPerCapitaStrategy')
const { SchoolDaysCoursesPerCapitaRanges } = require('./src/SchoolDaysCoursesPerCapitaRanges')
const { Trains } = require('./src/trains')
const { Grades } = require('./src/grades')
const { Grade } = require('./src/grade')
const { TrainsStrategy } = require('./src/TrainsStrategy')
const { SaturdayCoursesPerCapitaStrategy } = require('./src/SaturdayCoursesPerCapitaStrategy')
const { SundayCoursesPerCapitaStrategy } = require('./src/SundayCoursesPerCapitaStrategy')
const { SaturdayCoursesPerCapitaRanges } = require('./src/SaturdayCoursesPerCapitaRanges')
const { SundayCoursesPerCapitaRanges } = require('./src/SundayCoursesPerCapitaRanges')
const { AccessStrategy } = require('./src/AccessStrategy')
const { TownsWithAccessRanges } = require('./src/TownsWithAccessRanges')
const L = require('leaflet')
require('leaflet-sidebar-v2')
const { Problems } = require('./src/problems')
const { CutConnections } = require('./src/CutConnections')
const { UnrealizedPlan } = require('./src/UnrealizedPlan')

const mazoviaMap = maps.createMap('map')
maps.applyTileLayer(mazoviaMap)
mazoviaMap.on('baselayerchange', onLayerChange)

const sidebar = L.control.sidebar({
  autopan: false,
  closeButton: true,
  container: 'sidebar',
  position: 'right'
})
sidebar.addTo(mazoviaMap)

const tab = '<div class="arrow left"></div>'

function createPanel (id) {
  return {
    id: id,
    tab: tab,
    pane: document.getElementById(id).innerHTML
  }
}

class LayerIds {
  static general = 'transport-availability'
  static afternoons = 'transport-availability-late-afternoon'
  static summerBreak = 'transport-availability-summer-break'
  static weekends = 'transport-availability-weekends'
  static access = 'towns-with-access'
  static trains = 'trains-availability'
  static cities = 'cities'
  static problems = 'problems'
}

const panels = [
  createPanel(LayerIds.general),
  createPanel(LayerIds.afternoons),
  createPanel(LayerIds.summerBreak),
  createPanel(LayerIds.weekends),
  createPanel(LayerIds.access),
  createPanel(LayerIds.trains),
  createPanel(LayerIds.cities),
  {
    id: LayerIds.problems,
    tab: tab,
    pane: 'Kliknij na wyróżnione gminy, aby zobaczyć opis problemu'
  }
]
sidebar.addPanel(panels.find(panel => panel.id === LayerIds.general))

let activeId = LayerIds.general

function closeLayersControlOnMobile (event) {
  if (L.Browser.mobile) {
    layersControl.collapse()
  }
}

function onLayerChange (event) {
  changeText(event)
  zoomOut(event)
  closeLayersControlOnMobile(event)
}

function zoomOut (event) {
  mazoviaMap.zoomOut(10)
  mazoviaMap.panTo([52.232938, 21.0611941])
}

function changeText (e) {
  const layer = nameToId[e.name]
  sidebar.close()
  sidebar.removePanel(activeId)
  activeId = layer.id
  sidebar.addPanel(panels.find(panel => panel.id === layer.id))
  if (activeLegend !== undefined) {
    mazoviaMap.removeControl(activeLegend)
  }
  if (layer.legend !== undefined) {
    layer.legend.addTo(mazoviaMap)
  }
  activeLegend = layer.legend
}

function applyLayerToMap (vectorGrid, map) {
  maps.applyFeaturesAsVectorGridToMap(vectorGrid, map)
}

const loaded = []
let layersControl
const communities = new Communities()
const cutConnectionsStore = new Problems()
cutConnectionsStore.add(
  new CutConnections(
    [
      '1413011',
      '1461011',
      '1402011'
    ],
    'Licząca 52 tys. mieszkańców Ostrołękę, podobnie jak wiele innych miast ' +
    'średniej wielkości województwa mazowieckiego, cięcie w połączeniach' +
    ' dotknęło na przestrzeni ostatni lat. W połowie 2018, osiem lat po' +
    ' prywatyzacji, z miasta zniknął Mobilis. Od tej pory z niegdyś' +
    ' wojewódzkiej Ostrołęki nie ma jak dojechać do odległych na zachód o' +
    ' jedyne 80 km Mławy (34 tys. mieszkańców) i Ciechanowa (43 tys.).' +
    ' Niewiele lepiej jest z podróżami na wschód – do nieodległego Zambrowa' +
    ' (50 km) dojechać można tylko z przesiadką, a podróż trwa 4 godziny.' +
    ' Jak tłumaczył Mobilis, przerwanie połączeń było wynikiem' +
    ' „działań restrukturyzacyjnych” i „optymalizacji kosztowej oraz' +
    ' operacyjnej” wynikających z „obiektywnych przesłanek rynkowych”.' +
    ' Za tymi frazesami krył się jednak brak popytu na zbiorowy transport' +
    ' lokalny ludzi, których coraz bardziej kurcząca się siatka połączeń' +
    ' skłoniła, by przesiąść się do samochodów.'
  )
)
cutConnectionsStore.add(
  new CutConnections(
    [
      '1461011',
      '1462011'
    ],
    'Jeszcze co najmniej w 2016 na rozkładach autobusów można było znaleźć' +
    ' połączenie Białystok-Szczecin, które łączyło nie tylko te dwie' +
    ' wojewódzkie stolice na krańcach Polski, lecz przede wszystkim szereg' +
    ' znajdujących się po drodze miast, w tym na północy województwa' +
    ' mazowieckiego. W efekcie przykładowo 52-tysięczna Ostrołęka utraciła' +
    ' bezpośrednie połączenie z trzecim co do wielkości miastem' +
    ' w Mazowieckiem, 123-tys. Płockiem czy odwiedzanym przez' +
    ' kuracjuszy Ciechocinkiem. Szereg połączeń (m.in. z Toruniem)' +
    ' utraciła znajdująca się tuż za granicą województwa mazowieckiego Łomża.'
  )
)
cutConnectionsStore.add(
  new CutConnections(
    [
      '1412011',
      '1417052'
    ],
    'W 2018 w przeciągu miesiąca PKS Mińsk Mazowiecki zlikwidował 70' +
      ' połączeń, w zdecydowanej większości lokalnych. Szereg połączeń' +
      ' przerwano z dnia na dzień, a do siedziby powiatu nie można było' +
      ' dojechać z siedzib gmin, np. Kałuszyna czy Dobre. Chociaż część' +
      ' z nich po krótkim czasie zostało przywróconych przez' +
      ' innych przewoźników, wygląda na to, że niektóre miejscowości na' +
      ' trwałe utraciły połączenie z Mińskiem Mazowieckim. Dotyczy to' +
      ' chociażby odległej o niecałych 20 km 2-tysięcznej Kołbieli,' +
      ' która, co zapewne nie pomaga, leży tuż za granicą powiatu mińskiego.'
  )
)
cutConnectionsStore.add(
  new CutConnections(
    [
      '1462011',
      '1422011',
      '1405043'
    ],
    'Cięcia nie ominęły tez największego w zachodniej części województwa' +
    ' 123-tys. Płocka. Jak w przypadku wielu miast da się jeszcze w miarę' +
    ' dojechać stamtąd do Warszawy (choć można mieć wątpliwości,' +
    ' czy trasa 100 km powinna wynosić 2 godziny), o tyle zdecydowanie' +
    ' trudniej wygląda dojazd do innych miast w województwie.' +
    ' Płock w ciągu ostatnich kilku lat utracił bezpośrednie połączenie' +
    ' z odległymi o 100 km powiatowym Przasnyszem (w kierunku północnym)' +
    ' czy Grodziskiem Mazowieckim (w kierunku południowym). Od 2017' +
    ' zniknęły także połączenia do dużo dalszych, ale i większych miast:' +
    ' Szczecina czy Wrocławia – do stolicy Dolnego Śląska jazda wymaga' +
    ' dziś co najmniej dwóch przesiadek.'
  )
)
const unrealizedPlansStore = new Problems()
unrealizedPlansStore.add(
  new UnrealizedPlan(
    [
      '1418023',
      '1418032'
    ],
    'Rewitalizacja linii kolejowej nr 937 jeszcze w grudniu zeszłego roku znalazła się na liście programu Kolej Plus. Jednak już w styczniu remont linii zniknął z listy -  i to na wniosek władz Województwa Mazowieckiego. Dlaczego projekt wycofano? Odstąpiono od jego realizacji z uwagi na to, że PKP PLK podpisała w lutym 2020 r. z firmą BBF sp. z o.o. umowę na opracowanie dokumentacji przedprojektowej dla projektu pn. „Dobudowa torów aglomeracyjnych na odcinku Warszawa Al. Jerozolimskie – Piaseczno wraz z połączeniem do Konstancina – Jeziornej. Niestety fragment do Nowej Iwicznej pominięto.\n'
  )
)
unrealizedPlansStore.add(
  new UnrealizedPlan(
    [
      '1414011'
    ],
    'W grudniu 2019 r. PKP PLK zapowiadało, że prace na linii Modlin-Port Lotniczy Warszawa-Modlin rozpoczną się na przełomie 2020 i 2021 r, jednak już w maju 2020 r. Marszałek Adam Struzik przesunął realizację budowy 5 km zelektryfikowanej linii na perspektywę po 2023 r. Warty 123 mln zł łącznik miał dostać dofinansowanie UE rzędu 80 %. Inwestycja miała być realizowana fazowo, aczkolwiek nie zgadza się z tym Urząd Marszałkowski. W praktyce oznacza to kolejne długie lata oczekiwania.\n'
  )
)
unrealizedPlansStore.add(
  new UnrealizedPlan(
    [
      '1429011',
      '1464011',
      '1426082',
      '1464011'
    ],
    'Remont linii między Sokołowem Podlaskim a Małkinią to kolejny projekt wycofany przez samorząd wojewódzki. W tym wypadku magistrat tłumaczył, że mieszkańcy miejscowości położonych wzdłuż linii kolejowej nr 55 mogą liczyć na “dogodne” połączenie z Warszawą przez Siedlce. PKP PLK jak i samorząd tłumaczyli decyzję niższymi kosztami inwestycji. Rezygnacja z tego projektu właściwie przekreśla szansę na odtworzenie ciągu komunikacyjnego wzdłuż wschodniej granicy województwa: (Ostrołęka) – Ostrów Mazowiecka – Małkinia – Sokołów Podlaski – Siedlce (Łuków).\n'
  )
)
unrealizedPlansStore.add(
  new UnrealizedPlan(
    [
      '1419153',
      '1414063',
      '1465011',
      '1411011',
      '1424043'
    ],
    'Zgodnie z ustawą o Publicznym Transporcie Zbiorowym samorząd województwa ma obowiązek organizować i współfinansować połączenia uwzględnione w tzw. planie transportowym regionu. W 2014 roku Sejmik woj. mazowieckiego uchwalił plan zrównoważonego rozwoju publicznego transportu zbiorowego dla Województwa Mazowieckiego. Choć w dokumencie znalazły się zaledwie dwie wojewódzkie linie autobusowe użyteczności publicznej (vs. kilkanaście linii kolejowych) do tej pory nie zostały uruchomione.\n'
  )
)
const layers = {}
const communitiesBounds = {}
const nameToId = {}
const overviewLayers = {}
let activeLegend

function changeUnrealizedPlansDescription (e) {
  const currentProblems = unrealizedPlansStore.find(e.data.id)
  const container = document.getElementById(LayerIds.problems)
  container.innerHTML = ''
  for (const problem of currentProblems) {
    container.innerHTML += `<p>${problem.description}</p>`
  }
  const content = currentProblems.map(problem => `<p>${problem.description}</p>`).reduce((first, second) => first + second)
  sidebar.close()
  sidebar.removePanel(LayerIds.problems)
  sidebar.addPanel({
    id: LayerIds.problems,
    tab: tab,
    pane: '<h1>Niezrealizowane plany</h1>' + content
  })
  if (!L.Browser.mobile) {
    sidebar.open(LayerIds.problems)
  }
}

function changeCutConnectionsDescription (e) {
  const currentProblems = cutConnectionsStore.find(e.data.id)
  const container = document.getElementById(LayerIds.problems)
  container.innerHTML = ''
  for (const problem of currentProblems) {
    container.innerHTML += `<p>${problem.description}</p>`
  }
  const content = currentProblems.map(problem => `<p>${problem.description}</p>`).reduce((first, second) => first + second)
  sidebar.close()
  sidebar.removePanel(LayerIds.problems)
  sidebar.addPanel({
    id: LayerIds.problems,
    tab: tab,
    pane: '<h1>Cięcia połączeń</h1>' + content
  })
  sidebar.open(LayerIds.problems)
}

function createCommunityLayers (geoJSON) {
  const createCoursesVectorGrid = createVectorGridFactory(communities, communitiesBounds, new CoursesPerCapitaStrategy(new ExclusionCalculator(new CoursesPerCapitaRanges())))
  let grades = [
    new Grade({ description: 'Niedostępny', color: '#ffffff' }),
    new Grade({ description: '1-7 kursów na 1000 mieszkańców', color: '#f7fbff' }),
    new Grade({ description: '8-14 kursów na 1000 mieszkańców', color: '#c8ddf0' }),
    new Grade({ description: '15-24 kursów na 1000 mieszkańców', color: '#73b3d8' }),
    new Grade({ description: '25-49 kursów na 1000 mieszkańców', color: '#2879b9' }),
    new Grade({ description: '50+ kursów na 1000 mieszkańców', color: '#08306b' })
  ]
  let legend = createLegend(new Grades(grades))
  activeLegend = legend
  nameToId['Dostępność transportu zbiorowego'] = { id: LayerIds.general, legend: legend }
  layers['Dostępność transportu zbiorowego'] = createCoursesVectorGrid(geoJSON, mazoviaMap)
  legend.addTo(mazoviaMap)

  const createLateAfternoonCoursesVectorGrid = createVectorGridFactory(communities, communitiesBounds, new LateAfternoonCoursesPerCapitaStrategy(new ExclusionCalculator(new LateAfternoonCoursesPerCapitaRanges())))
  grades = [
    new Grade({ description: 'Niedostępny', color: '#ffffff' }),
    new Grade({ description: '1-3 kursów na 1000 mieszkańców', color: '#f7fbff' }),
    new Grade({ description: '4-7 kursów na 1000 mieszkańców', color: '#c8ddf0' }),
    new Grade({ description: '8-11 kursów na 1000 mieszkańców', color: '#73b3d8' }),
    new Grade({ description: '12-19 kursów na 1000 mieszkańców', color: '#2879b9' }),
    new Grade({ description: '20 lub więcej kursów na 1000 mieszkańców', color: '#08306b' })
  ]
  legend = createLegend(new Grades(grades))
  nameToId['Dostępność transportu zbiorowego po 15:00'] = { id: LayerIds.afternoons, legend: legend }
  layers['Dostępność transportu zbiorowego po 15:00'] = createLateAfternoonCoursesVectorGrid(geoJSON, mazoviaMap)

  const createVacationCoursesVectorGrid = createVectorGridFactory(communities, communitiesBounds, new SchoolDaysCoursesPerCapitaStrategy(new ExclusionCalculator(new SchoolDaysCoursesPerCapitaRanges())))
  grades = [
    new Grade({ description: 'Niedostępny', color: '#ffffff' }),
    new Grade({ description: '1-7 kursów na 1000 mieszkańców', color: '#f7fbff' }),
    new Grade({ description: '8-14 kursów na 1000 mieszkańców', color: '#c8ddf0' }),
    new Grade({ description: '15-24 kursów na 1000 mieszkańców', color: '#73b3d8' }),
    new Grade({ description: '25-49 kursów na 1000 mieszkańców', color: '#2879b9' }),
    new Grade({ description: '50 lub więcej kursów na 1000 mieszkańców', color: '#08306b' })
  ]
  legend = createLegend(new Grades(grades))
  nameToId['Dostępność transportu zbiorowego w dni bez nauki szkolnej'] = { id: LayerIds.summerBreak, legend: legend }
  layers['Dostępność transportu zbiorowego w dni bez nauki szkolnej'] = createVacationCoursesVectorGrid(geoJSON, mazoviaMap)

  const createSaturdayCoursesVectorGrid = createVectorGridFactory(communities, communitiesBounds, new SaturdayCoursesPerCapitaStrategy(new ExclusionCalculator(new SaturdayCoursesPerCapitaRanges())))
  grades = [
    new Grade({ description: 'Niedostępny', color: '#ffffff' }),
    new Grade({ description: '1-2 kursów na 1000 mieszkańców', color: '#f7fbff' }),
    new Grade({ description: '3-4 kursów na 1000 mieszkańców', color: '#c8ddf0' }),
    new Grade({ description: '5-9 kursów na 1000 mieszkańców', color: '#73b3d8' }),
    new Grade({ description: '10-19 kursów na 1000 mieszkańców', color: '#2879b9' }),
    new Grade({ description: '20+ kursów na 1000 mieszkańców', color: '#08306b' })
  ]
  legend = createLegend(new Grades(grades))
  nameToId['Dostępność transportu zbiorowego w soboty'] = { id: LayerIds.weekends, legend: legend }
  layers['Dostępność transportu zbiorowego w soboty'] = createSaturdayCoursesVectorGrid(geoJSON, mazoviaMap)

  const createSundayCoursesVectorGrid = createVectorGridFactory(communities, communitiesBounds, new SundayCoursesPerCapitaStrategy(new ExclusionCalculator(new SundayCoursesPerCapitaRanges())))
  grades = [
    new Grade({ description: 'Niedostępny', color: '#ffffff' }),
    new Grade({ description: '1-2 kursów na 1000 mieszkańców', color: '#f7fbff' }),
    new Grade({ description: '3-4 kursów na 1000 mieszkańców', color: '#c8ddf0' }),
    new Grade({ description: '5-9 kursów na 1000 mieszkańców', color: '#73b3d8' }),
    new Grade({ description: '10-19 kursów na 1000 mieszkańców', color: '#2879b9' }),
    new Grade({ description: '20+ kursów na 1000 mieszkańców', color: '#08306b' })
  ]
  legend = createLegend(new Grades(grades))
  nameToId['Dostępność transportu zbiorowego w niedziele i święta'] = {
    id: LayerIds.weekends,
    legend: legend
  }
  layers['Dostępność transportu zbiorowego w niedziele i święta'] = createSundayCoursesVectorGrid(geoJSON, mazoviaMap)

  const createTownsWithAccessVectorGrid = createVectorGridFactory(communities, communitiesBounds, new AccessStrategy(new ExclusionCalculator(TownsWithAccessRanges)))
  grades = [
    new Grade({ description: 'Brak miejscowości z dostępem do transportu publicznego', color: '#ffffff' }),
    new Grade({ description: '1-2 miejscowości z dostępem do transportu publicznego', color: '#f7fbff' }),
    new Grade({ description: '3-4  miejscowości z dostępem do transportu publicznego', color: '#c8ddf0' }),
    new Grade({ description: '5-9 miejscowości z dostępem do transportu publicznego', color: '#73b3d8' }),
    new Grade({ description: '10-19 miejscowości z dostępem do transportu publicznego', color: '#2879b9' }),
    new Grade({ description: '20+ miejscowości z dostępem do transportu publicznego', color: '#08306b' })
  ]
  legend = createLegend(new Grades(grades))
  nameToId['Dostępność przystanków'] = {
    id:LayerIds.access,
    legend: legend
  }
  layers['Dostępność przystanków'] = createTownsWithAccessVectorGrid(geoJSON, mazoviaMap)

  const createTrainsAvailabilityVectorGrid = createVectorGridFactory(communities, communitiesBounds, new TrainsStrategy())
  grades = [
    new Grade({ description: 'Brak torów', color: '#ffffff' }),
    new Grade({ description: 'Są tory, ale brak połączeń pasażerskich', color: '#73b3d8' }),
    new Grade({ description: 'Dostępne kolejowe połączenia pasażerskie', color: '#08306b' })
  ]
  legend = createLegend(new Grades(grades))
  nameToId['Dostępność kolei w gminie'] = { id: LayerIds.trains, legend: legend }
  layers['Dostępność kolei w gminie'] = createTrainsAvailabilityVectorGrid(geoJSON, mazoviaMap)

  const createCutConnectionVectorGrid = createProblemVectorGridFactory(cutConnectionsStore, communitiesBounds)
  nameToId['Cięcia połączeń'] = { id: LayerIds.problems }
  layers['Cięcia połączeń'] = createCutConnectionVectorGrid(geoJSON, mazoviaMap)
  layers['Cięcia połączeń'].on('selected', changeCutConnectionsDescription)

  const createUnrealizedPlansVectorGrid = createProblemVectorGridFactory(unrealizedPlansStore, communitiesBounds)
  nameToId['Niezrealizowane plany'] = { id: LayerIds.problems }
  layers['Niezrealizowane plany'] = createUnrealizedPlansVectorGrid(geoJSON, mazoviaMap)
  layers['Niezrealizowane plany'].on('selected', changeUnrealizedPlansDescription)
}

const dataToTrains = function (number) {
  if (number === 0 || isNaN(number)) {
    return new Trains(false, false)
  } else if (number === 1) {
    return new Trains(true, false)
  } else if (number === 2) {
    return new Trains(true, true)
  } else {
    throw new Error(`number ${number} is invalid`)
  }
}

shapes.loadCommunities().then(
  geoJSON => {
    const loadedCommunities = loadGeoData(geoJSON, (feature, layer) => {
      const community = new Community(
        feature.properties.JPT_KOD_JE,
        feature.properties.nazwa || feature.properties.JPT_NAZWA_,
        feature.properties.przystanki,
        feature.properties.przystanki,
        feature.properties.kursy,
        feature.properties.kursy_pc,
        feature.properties.kursy_15_p,
        feature.properties.kursy_sd_p,
        feature.properties.kursy_sa_1,
        feature.properties.kursy_su_1,
        feature.properties.trasy,
        dataToTrains(feature.properties.kolej)
      )
      communities.save(community)
      communitiesBounds[feature.properties.JPT_KOD_JE] = layer.getBounds()
    })
    mazoviaMap.flyToBounds(loadedCommunities.getBounds())
    maps.createBounds(loadedCommunities, mazoviaMap)
    return geoJSON
  }
).then(geoJSON => {
  createCommunityLayers(geoJSON, mazoviaMap)
}).then(() => {
  applyLayerToMap(layers['Dostępność transportu zbiorowego'], mazoviaMap)
  loaded.push('communities')
})

shapes.loadTowns().then(geoJSON => {
  nameToId['Badane miasta'] = { id: LayerIds.cities }
  layers['Badane miasta'] = L.geoJSON(geoJSON, {
    pointToLayer: (geoJsonPoint, latlng) => {
      return new L.CircleMarker(latlng, { radius: 0.1 })
    },
    onEachFeature: (feature, layer) => {
      layer.bindTooltip(feature.properties.name, { permanent: true })
    },
    filter: geoJsonFeature => {
      return ['Ostrów Mazowiecka', 'Tczów', 'Żuromin'].includes(geoJsonFeature.properties.name)
    }
  })
}).then(() => loaded.push(LayerIds.cities))

shapes.loadRoads().then(geoJSON => {
  return geoJSON
}).then(geoJSON => {
  overviewLayers.Drogi = createSimpleVectorGrid(geoJSON, new LayerStyle('#000000', 1))
}
).then(() => loaded.push('roads'))

shapes.loadRailways().then(geoJSON => {
  return geoJSON
}).then(geoJSON => {
  overviewLayers.Koleje = createSimpleVectorGrid(geoJSON, new LayerStyle('#FEE600', 2))
}).then(() => loaded.push('railways'))

shapes.loadDistricts().then(geoJSON => {
  return geoJSON
}).then(geoJSON => {
  overviewLayers.Powiaty = createSimpleVectorGrid(geoJSON, new LayerStyle('#aaaaaa', 3))
}).then(() => loaded.push('districts'))

shapes.loadTowns().then(geoJSON => {
  return geoJSON
}).then((geoJSON) => {
  overviewLayers.Miejscowości = loadGeoJSONLayer(geoJSON)
}).then(() => loaded.push('towns'))

function allLoaded () {
  if (loaded.includes('communities') && loaded.includes(LayerIds.cities) && loaded.includes('districts') && loaded.includes('roads') && loaded.includes('railways') && loaded.includes('towns')) {
    layersControl = maps.createLayersControl(layers, overviewLayers, mazoviaMap)
  } else {
    setTimeout(allLoaded, 100)
  }
}
allLoaded()
