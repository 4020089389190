export class Trains {
  public tracks: boolean;
  public passengerTrains: boolean;

  constructor (tracks: boolean, passengerTrains: boolean) {
    if (!(tracks) && passengerTrains) {
      throw new Error('Trains cannot be available in communities without tracks')
    }
    this.tracks = tracks
    this.passengerTrains = passengerTrains
  }
}
