import { ExclusionStrategy } from './ExclusionStrategy'
import { ExclusionCalculatorInterface } from './ExclusionCalculator'
import { Community } from './community'
import { CommunityExclusionFactor } from './CommunityExclusionFactor'

export class SchoolDaysCoursesPerCapitaStrategy implements ExclusionStrategy {
    private exclusionCalculator: ExclusionCalculatorInterface;

    constructor (exclusionCalculator: ExclusionCalculatorInterface) {
      this.exclusionCalculator = exclusionCalculator
    }

    exclusion (community: Community): CommunityExclusionFactor {
      return this.exclusionCalculator.select(community.schoolFreeDaysCoursesPerCapita)
    }
}
