import { Ranges } from './Ranges'
import { CommunityExclusionFactor } from './CommunityExclusionFactor'

export interface ExclusionCalculatorInterface {
    select (value: number): CommunityExclusionFactor
}

export class ExclusionCalculator implements ExclusionCalculatorInterface {
    private ranges: Ranges;

    constructor (ranges: Ranges) {
      this.ranges = ranges
    }

    select (value: number): CommunityExclusionFactor {
      if (value === this.ranges.None) {
        return CommunityExclusionFactor.NoOptions
      } else if (this.notBiggerNorEqual(value, this.ranges.VeryLimited)) {
        return CommunityExclusionFactor.VeryLimitedOptions
      } else if (this.notBiggerNorEqual(value, this.ranges.Limited)) {
        return CommunityExclusionFactor.LimitedOptions
      } else if (this.notBiggerNorEqual(value, this.ranges.Regular)) {
        return CommunityExclusionFactor.RegularOptions
      } else if (this.notBiggerNorEqual(value, this.ranges.Many)) {
        return CommunityExclusionFactor.GoodOptions
      } else {
        return CommunityExclusionFactor.VeryGoodOptions
      }
    }

    private notBiggerNorEqual (value: number, range: number): boolean {
      return !(value >= range)
    }
}
