import { ExclusionCalculatorInterface } from './ExclusionCalculator'
import { Community } from './community'
import { CommunityExclusionFactor } from './CommunityExclusionFactor'

export class LateAfternoonCoursesPerCapitaStrategy {
    private exclusionCalculator: ExclusionCalculatorInterface;

    constructor (exclusionCalculator: ExclusionCalculatorInterface) {
      this.exclusionCalculator = exclusionCalculator
    }

    exclusion (community: Community): CommunityExclusionFactor {
      const value = community.lateAfternoonCoursesPerCapita
      return this.exclusionCalculator.select(value)
    }
}
