import { ExclusionStrategy } from './ExclusionStrategy'
import { CommunityExclusionFactor } from './CommunityExclusionFactor'
import { Community } from './community'
import { ExclusionCalculatorInterface } from './ExclusionCalculator'

export class AccessStrategy implements ExclusionStrategy {
  private calculator: ExclusionCalculatorInterface

  constructor (calculator: ExclusionCalculatorInterface) {
    this.calculator = calculator
  }

  exclusion (community: Community): CommunityExclusionFactor {
    return this.calculator.select(community.townsWithAccess)
  }
}
