import * as L from 'leaflet'
import 'leaflet.vectorgrid'
import * as geojson from 'geojson'
import { Communities } from './communities'
import { ExclusionStrategy } from './ExclusionStrategy'
import { LayerStyle } from './LayerStyle'
import { Grades } from './grades'
import { CommunityExclusionFactor } from './CommunityExclusionFactor'
import { TrainsExclusion } from './TrainsExclusion'
import { CutConnections } from './CutConnections'
import { UnrealizedPlan } from './UnrealizedPlan'
import { Problems } from './problems'

function createMap (mapId: string): L.Map {
  return L.map(mapId, { center: [52.232938, 21.0611941], zoom: 8, minZoom: 7, maxZoom: 10 })
}

function applyTileLayer (map: L.Map): void {
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map)
}

function loadGeoData (geoData: geojson.FeatureCollection, perFeatureAction?: (feature: geojson.Feature, layer: L.Layer) => void): L.GeoJSON {
  return L.geoJSON(geoData, {
    onEachFeature: (feature, layer) => {
      if (perFeatureAction !== undefined) {
        perFeatureAction(feature, layer)
      }
    }
  })
}

function chooseColorBy (exclusionFactor: CommunityExclusionFactor | TrainsExclusion): string {
  return (exclusionFactor === CommunityExclusionFactor.NoOptions || exclusionFactor === TrainsExclusion.noTracks)
    ? '#ffffff'
    : (exclusionFactor === CommunityExclusionFactor.VeryLimitedOptions)
        ? '#f7fbff'
        : (exclusionFactor === CommunityExclusionFactor.LimitedOptions)
            ? '#c8ddf0'
            : (exclusionFactor === CommunityExclusionFactor.RegularOptions || exclusionFactor === TrainsExclusion.tracksOnly)
                ? '#73b3d8'
                : (exclusionFactor === CommunityExclusionFactor.GoodOptions)
                    ? '#2879b9'
                    : '#08306b'
}
// @ts-ignore
function createVectorGridFactory (communities: Communities, communitiesBounds: object, strategy: ExclusionStrategy): (geojson: geojson.FeatureCollection, map: L.Map) => L.VectorGrid {
  function createStyle (color: string, opacity: number, weight: number) {
    return {
      fillColor: color,
      stroke: true,
      fillOpacity: opacity,
      fill: true,
      color: color,
      opacity: 1,
      weight: weight
    }
  }

  // @ts-ignore
  function createVectorGrid (geojson: geojson.FeatureCollection, map: L.Map): L.vectorGrid {
    // @ts-ignore
    const vectorGrid = L.vectorGrid.slicer(geojson, {
      // @ts-ignore
      rendererFactory: L.svg.tile,
      vectorTileLayerStyles: {
        sliced: function (properties, zoom) {
          const community = communities.find(properties.JPT_KOD_JE)
          const color = chooseColorBy(community.exclusionFactor(strategy))
          const opacity = 0.7
          const weight = 0
          return createStyle(color, opacity, weight)
        }
      },
      interactive: true,
      getFeatureId: function (f) {
        return f.properties.JPT_KOD_JE
      }
    }).on('mouseover', function (e) {
      const properties = e.layer.properties
      const community = communities.find(properties.JPT_KOD_JE)
      const color = chooseColorBy(community.exclusionFactor(strategy))
      const weight = 2
      const opacity = 0.9
      const style = createStyle(color, opacity, weight)
      vectorGrid.setFeatureStyle(properties.JPT_KOD_JE, style)
    }).on('mouseout', function (e) {
      // @ts-ignore
      const properties = e.layer.properties
      const community = communities.find(properties.JPT_KOD_JE)
      L.popup().closePopup()
      const color = chooseColorBy(community.exclusionFactor(strategy))
      const weight = 0
      const opacity = 0.7
      const style = createStyle(color, opacity, weight)
      vectorGrid.setFeatureStyle(properties.JPT_KOD_JE, style)
    }).on('click', function (e) {
      const community = communities.find(e.layer.properties.JPT_KOD_JE)
      L.popup()
        .setContent('gmina: ' + community.name + '</br>')
        .setLatLng(e.latlng)
        .openOn(map)
      map.flyToBounds(communitiesBounds[e.layer.properties.JPT_KOD_JE])
    })
    return vectorGrid
  }
  return createVectorGrid
}

// @ts-ignore
export function createProblemVectorGridFactory (problems: Problems, communitiesBounds: object): (geojson: geojson.FeatureCollection, map: L.Map) => L.VectorGrid {
  class Style {
    static normal (problems: CutConnections[] | UnrealizedPlan[]): Object {
      return {
        fillColor: '#73b3d8',
        stroke: true,
        fillOpacity: this.exists(problems) ? 0 : 0.9,
        fill: true,
        color: '#fff',
        opacity: this.exists(problems) ? 0 : 0.9,
        weight: 1
      }
    }

    static highlighted (problems: CutConnections[] | UnrealizedPlan[]): Object {
      return {
        fillColor: '#73b3d8',
        stroke: true,
        fillOpacity: this.exists(problems) ? 0 : 0.9,
        fill: true,
        color: '#08306b',
        opacity: this.exists(problems) ? 0 : 0.9,
        weight: 1
      }
    }

    static selected (problems: CutConnections[] | UnrealizedPlan[]): Object {
      return {
        fillColor: '#08306b',
        stroke: true,
        fillOpacity: this.exists(problems) ? 0 : 0.9,
        fill: true,
        color: '#08306b',
        opacity: this.exists(problems) ? 0 : 0.9,
        weight: 1
      }
    }

    private static exists (problems: CutConnections[] | UnrealizedPlan[]): boolean {
      return problems.length === 0
    }
  }

  function problemExists (communityProblems: CutConnections[] | UnrealizedPlan[]): boolean {
    return communityProblems.length !== 0
  }

  // @ts-ignore
  function createVectorGrid (geojson: geojson.FeatureCollection, map: L.Map): L.vectorGrid {
    // @ts-ignore
    const vectorGrid = L.vectorGrid.slicer(geojson, {
      // @ts-ignore
      rendererFactory: L.svg.tile,
      vectorTileLayerStyles: {
        sliced: function (properties, zoom) {
          return Style.normal(problems.find(properties.JPT_KOD_JE))
        }
      },
      interactive: true,
      getFeatureId: (feature) => feature.properties.JPT_KOD_JE
    }).on('mouseover', function (e) {
      const properties = e.propagatedFrom.properties
      const communityProblems: CutConnections[] | UnrealizedPlan[] = problems.find(properties.JPT_KOD_JE)
      if (problemExists(communityProblems)) {
        const style = Style.highlighted(communityProblems)
        vectorGrid.setFeatureStyle(properties.JPT_KOD_JE, style)
        this.fire('over', { data: { id: properties.JPT_KOD_JE, layer: e.layer } }, true)
      }
    }).on('mouseout', function (e) {
      const properties = e.propagatedFrom.properties
      const communityProblems: CutConnections[] | UnrealizedPlan[] = problems.find(properties.JPT_KOD_JE)
      if (problemExists(communityProblems)) {
        const style = Style.normal(problems.find(properties.JPT_KOD_JE))
        vectorGrid.setFeatureStyle(properties.JPT_KOD_JE, style)
        this.fire('out', { data: { id: properties.JPT_KOD_JE, layer: e.layer } }, true)
      }
    }).on('click', function (e) {
      const properties = e.propagatedFrom.properties
      const communityProblems: CutConnections[] | UnrealizedPlan[] = problems.find(properties.JPT_KOD_JE)
      if (problemExists(communityProblems)) {
        const style = Style.selected(problems.find(properties.JPT_KOD_JE))
        vectorGrid.setFeatureStyle(properties.JPT_KOD_JE, style)
        const points = []
        for (const problem of communityProblems) {
          for (const id of problem.ids) {
            points.push(communitiesBounds[id])
          }
        }
        const bounds: L.LatLngBounds = L.latLngBounds(points)
        map.flyToBounds(bounds, { maxZoom: 10 })
        this.fire('selected', {
          data: {
            id: properties.JPT_KOD_JE,
            layer: e.layer
          }
        }, true)
      }
    }).on('selected', function (e) {
      const affected = problems.find(e.data.id)
      const style = Style.selected(affected)
      for (const problem of affected) {
        for (const id of problem.ids) {
          vectorGrid.setFeatureStyle(id, style)
        }
      }
    }).on('over', function (e) {
      const affected = problems.find(e.data.id)
      const style = Style.highlighted(affected)
      for (const problem of affected) {
        for (const id of problem.ids) {
          vectorGrid.setFeatureStyle(id, style)
        }
      }
    }).on('out', function (e) {
      const affected = problems.find(e.data.id)
      const style = Style.normal(affected)
      for (const problem of affected) {
        for (const id of problem.ids) {
          vectorGrid.setFeatureStyle(id, style)
        }
      }
    })
    return vectorGrid
  }
  return createVectorGrid
}

// @ts-ignore
export function createSimpleVectorGrid (geoJSON: geojson.FeatureCollection, style?: LayerStyle): L.vectorGrid {
  const options = {
    vectorTileLayerStyles: {
      sliced: function (properties, zoom) {
        return {
          color: style ? style.color : '#3388ff',
          weight: style ? style.weight : 3
        }
      }
    }
  }
  // @ts-ignore
  return L.vectorGrid.slicer(geoJSON, options)
}

export function loadGeoJSONLayer (geoJSON: geojson.GeoJSON): L.GeoJSON {
  return L.geoJSON(geoJSON, {
    pointToLayer (geoJsonPoint: geojson.Feature<geojson.Point, any>, latlng: L.LatLng): L.Layer {
      return new L.CircleMarker(latlng, { radius: 0.1 })
    },
    filter: geoJsonFeature => {
      return ['city', 'town'].includes(geoJsonFeature.properties.fclass)
    }
  })
}

// @ts-ignore
function applyFeaturesAsVectorGridToMap (vectorGrid: L.VectorGrid, map: L.Map): void {
  vectorGrid.addTo(map)
}

function createLayersControl (layers: Record<string, L.FeatureGroup>, overviewLayers: Record<string, L.FeatureGroup>, map: L.Map): L.Control.Layers {
  return L.control.layers(layers, overviewLayers, { collapsed: true }).addTo(map)
}

function createBounds (layer: L.GeoJSON, map: L.Map): void {
  const bounds: L.LatLngBounds = L.latLngBounds(
    [
      55.48076140655203,
      25.12259397076747
    ],
    [
      49.015947630198454,
      17.26433354135903
    ]
  )
  map.setMaxBounds(
    bounds
  )
}

class Legend extends L.Control {
  private grades: Grades;
  private div: HTMLDivElement;
  constructor (grades: Grades, options?: L.ControlOptions) {
    super(options)
    this.grades = grades
  }

  onAdd (map: L.Map): HTMLElement {
    this.div = L.DomUtil.create('div', 'info legend')
    for (const grade of this.grades.grades) {
      this.div.innerHTML +=
          '<i style="background:' + grade.color + '"></i> ' + grade.description + '</br>'
    }
    return this.div
  }

  onRemove (map: L.Map): void {
    this.div.remove()
  }
}

export function createLegend (grades: Grades): Legend {
  return new Legend(grades, { position: 'bottomright' })
}

exports.createMap = createMap
exports.createBounds = createBounds
exports.applyTileLayer = applyTileLayer
exports.loadGeoData = loadGeoData
exports.createVectorGrid = createVectorGridFactory
exports.applyFeaturesAsVectorGridToMap = applyFeaturesAsVectorGridToMap
exports.createLayersControl = createLayersControl

export { createMap, createBounds, applyTileLayer, loadGeoData, createVectorGridFactory, applyFeaturesAsVectorGridToMap, createLayersControl }
