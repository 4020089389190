import { Community } from './community'

export class Communities {
    private communities: Community[] = []

    save (community: Community): void {
      this.communities.push(community)
    }

    find (id: string): Community {
      return this.communities.find(community => community.id === id)
    }
}
