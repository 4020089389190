import { ExclusionCalculatorInterface } from './ExclusionCalculator'
import { Community } from './community'
import { ExclusionFactor } from './ExclusionFactor'

export class CoursesPerCapitaStrategy {
    private exclusionCalculator: ExclusionCalculatorInterface;

    // eslint-disable-next-line no-undef
    constructor (exclusionCalculator: ExclusionCalculatorInterface) {
      this.exclusionCalculator = exclusionCalculator
    }

    exclusion (community: Community): ExclusionFactor {
      const value = community.coursesPerCapita
      return this.exclusionCalculator.select(value)
    }
}
