import { ExclusionCalculatorInterface } from './ExclusionCalculator'
import { Community } from './community'

export class SaturdayCoursesPerCapitaStrategy {
    private calculator: ExclusionCalculatorInterface;

    constructor (calculator: ExclusionCalculatorInterface) {
      this.calculator = calculator
    }

    exclusion (community: Community) {
      return this.calculator.select(community.saturdayCoursesPerCapita)
    }
}
