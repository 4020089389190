import * as shp from 'shpjs'

function loadShape (name: string): Promise<shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[]> {
  const host = window.location.host
  return shp(location.protocol + '//' + host + '/resources/shapes/' + name)
}

function loadCommunities (): Promise<shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[]> {
  return loadShape('gminy_all/gminy_all')
}

function loadRoads (): Promise<shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[]> {
  return loadShape('drogi/drogi')
}

function loadRailways (): Promise<shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[]> {
  return loadShape('koleje/koleje_clip')
}

function loadTowns (): Promise<shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[]> {
  return loadShape('miejscowosci/miejscowosci')
}

function loadDistricts (): Promise<shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[]> {
  return loadShape('powiaty_generalized/powiaty_generalized')
}

function selectProtocol () {
  return window.location.protocol
}

export { loadCommunities, loadRoads, loadRailways, loadTowns, loadDistricts, selectProtocol }
