import { ExclusionStrategy } from './ExclusionStrategy'
import { Trains } from './trains'
import { ExclusionFactor } from './ExclusionFactor'

export class Community {
  readonly id: string;
  readonly name: string;
  readonly townsWithAccess: number;
  readonly allStops: number;
  readonly courses: number;
  readonly coursesPerCapita: number;
  readonly lateAfternoonCoursesPerCapita: number;
  readonly schoolFreeDaysCoursesPerCapita: number;
  readonly saturdayCoursesPerCapita: number;
  readonly sundayCoursesPerCapita: number;
  readonly routes: number;
  readonly trains: Trains;

  constructor (
    id: string,
    name: string,
    stops: number,
    allStops: number,
    courses: number,
    coursesPerCapita: number,
    lateAfternoonCoursesPerCapita: number,
    schoolFreeDaysCoursesPerCapita: number,
    saturdayCoursesPerCapita: number,
    sundayCoursesPerCapita: number,
    routes: number,
    trains: Trains
  ) {
    this.id = id
    this.name = name
    this.townsWithAccess = stops
    this.allStops = allStops
    this.courses = courses
    this.coursesPerCapita = coursesPerCapita
    this.lateAfternoonCoursesPerCapita = lateAfternoonCoursesPerCapita
    this.schoolFreeDaysCoursesPerCapita = schoolFreeDaysCoursesPerCapita
    this.saturdayCoursesPerCapita = saturdayCoursesPerCapita
    this.sundayCoursesPerCapita = sundayCoursesPerCapita
    this.routes = routes
    this.trains = trains
  }

  exclusionFactor (strategy: ExclusionStrategy): ExclusionFactor {
    return strategy.exclusion(this)
  }
}
